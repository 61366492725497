import { defineStore, acceptHMRUpdate } from 'pinia';
import { defineAsyncComponent, ref, shallowReactive } from 'vue';
import { useModal } from 'vue-final-modal';
import { createLogger } from '@/utils';
import { getMeetingById } from '@/api/meetings';
import { Meeting } from '@/api/meetings/types';
import {
    useLiveKitRoom,
    useLocalParticipantMetadata,
    usePersistentUserChoices,
    useRecordingState,
    useRoomSockets,
} from '@/composables';
import { useAuthStore } from './auth';
import { useUserStore } from './user';
import { Member } from '@/api/members/types';
import { getMeetingMembers } from '@/api/members';
import { LivekitMessageManager } from '@/libs/livekit-message-manager';
import { useRoomNotificationsStore } from './roomNotifications';
import i18n from '../../i18n';
import { MeetingUserRole } from '../../constants/roles';
import { UpdateRolePayload } from '../../interfaces/webinar';

export const useRoomStore = defineStore('room', () => {
    const logger = createLogger('room');

    const authStore = useAuthStore();
    const userStore = useUserStore();
    const roomNotificationsStore = useRoomNotificationsStore();
    const { userChoices } = usePersistentUserChoices();

    const members = ref<Record<string, Member>>({});
    const meetingRoom = ref<Meeting>();
    const { room: lkRoom, connectToRoom } = useLiveKitRoom();

    const lkDataChannel = shallowReactive(new LivekitMessageManager(lkRoom));

    const { vcsSocket, analyticsSocket } = useRoomSockets();
    const { recordingState, loadRecordingState, toggleRecordingState } =
        useRecordingState();

    const setMeetingRoom = async (meetingId: string) => {
        const response = await getMeetingById(meetingId);

        if (!response.success) {
            logger.error(response.message);
            return;
        }

        meetingRoom.value = response.data;
    };

    const enterRoom = async () => {
        await Promise.all([vcsSocket.connect(), analyticsSocket.connect()]);

        vcsSocket
            .on('onEnterMeeting', async ({ livekitToken }) => {
                await connectToRoom(livekitToken);
            })
            .on('onClientJoined', async (payload) => {
                vcsSocket.setReconnectCallback(async () => {
                    await authStore.refreshTokens();

                    vcsSocket.emit('Reconnect', {
                        meeting_id: meetingRoom.value?.id!,
                        token: `Bearer ${authStore.accessToken}`,
                        identity: lkRoom.localParticipant.identity,
                    });
                });

                analyticsSocket.emit('JoinRoom', payload);
                analyticsSocket.setReconnectCallback(async () => {
                    await authStore.refreshTokens();

                    analyticsSocket.emit('JoinRoom', {
                        ...payload,
                        token: `Bearer ${authStore.accessToken}`,
                    });
                });
            })
            .on('onUpdateRole', updateMembers)
            .on('onCanMemberWrite', updateMembers)
            .on('onUpdateParticipantMetadata', () => {
                const { updateParticipantMetadata } =
                    useLocalParticipantMetadata();

                updateParticipantMetadata({
                    isCameraMirroring: userChoices.videoMirrored,
                });
            });

        vcsSocket.emit('JoinToLobby', {
            meeting_id: meetingRoom.value!.id,
            token: `Bearer ${authStore.accessToken}`,
        });

        lkDataChannel
            .on('UpdateMemberRole', async () => {
                await Promise.allSettled([
                    updateMembers(),
                    userStore.handlePermissions(meetingRoom.value?.id!),
                ]);
            })
            .on('ToggleMicrophone', async (payload) => {
                if (payload.enabled) {
                    return roomNotificationsStore.addRequestNotification({
                        type: 'microphone',
                        ...payload,
                    });
                }

                await lkRoom.localParticipant.setMicrophoneEnabled(false);
            })
            .on('ToggleCamera', async (payload) => {
                if (payload.enabled) {
                    return roomNotificationsStore.addRequestNotification({
                        type: 'camera',
                        ...payload,
                    });
                }

                await lkRoom.localParticipant.setCameraEnabled(false);
            });
    };

    const beforeLeaveFromRoom = async () => {
        if (userStore.isOrganizer && !recordingState.isStopped) {
            await toggleRecordingState();
        }
    };

    const leaveFromRoom = async () => {
        beforeLeaveFromRoom();

        const meetingId = meetingRoom.value?.id;

        await lkRoom.disconnect(true);
        lkDataChannel.terminate();

        vcsSocket.emit('LeaveFromMeeting');
        vcsSocket.disconnect();
        analyticsSocket.disconnect();

        if (userStore.isOrganizer || !meetingId) {
            return;
        }

        useModal({
            component: defineAsyncComponent(
                () => import('@/blocks/RatingModal.vue'),
            ),
            defaultModelValue: true,
            keepAlive: false,
            attrs: { meetingId },
        });
    };

    const updateMembers = async () => {
        const meetingId = meetingRoom.value?.id!;

        const response = await getMeetingMembers(meetingId);

        if (!response.success) {
            logger.error(response.message);
            return;
        }

        members.value = response.data;
    };

    const updateParticipantRole = (payload: UpdateRolePayload) => {
        vcsSocket.emit('UpdateMemberRole', payload);
        lkDataChannel.emit('UpdateMemberRole', null, {
            destinationIdentities: [`${payload.memberId}`],
        });

        if (payload.role === MeetingUserRole.CoOrganizer) {
            lkDataChannel.emit('Notification', {
                nickname: payload.nickname,
                icon: ['fas', 'user-check'],
                action: i18n.global.t(
                    'vks.notifications.reactions.action.become-speaker',
                ),
            });
        }
    };

    return {
        lkRoom,
        lkDataChannel,
        meetingRoom,
        members,
        vcsSocket,
        analyticsSocket,
        recordingState,
        loadRecordingState,
        toggleRecordingState,
        setMeetingRoom,
        updateMembers,
        updateParticipantRole,
        enterRoom,
        beforeLeaveFromRoom,
        leaveFromRoom,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRoomStore, import.meta.hot));
}
