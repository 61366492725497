import {
    Room,
    MediaDeviceFailure,
    RoomEvent,
    LogLevel,
    setLogLevel,
    Participant,
    VideoPresets,
} from 'livekit-client';
import { shallowReactive } from 'vue';
import { usePersistentUserChoices } from './usePersistentUserChoices';
import { useRoomStore } from '@/pinia/stores/room';
import enterAudio from '@/assets/sounds/enter.wav';
import leaveAudio from '@/assets/sounds/leave-meeting.wav';
import { NotificationType } from '../interfaces/webinar';
import i18n from '../i18n';
import { useLayoutStore } from '../pinia/stores/layout';
import { useUserStore } from '../pinia/stores/user';
import { redirect } from '../utils';
import { useRoomNotificationsStore } from '../pinia/stores/roomNotifications';

export interface LiveKitRoomOptions {
    onError?: (error: Error) => void;
    onMediaDeviceFailure?: (failure?: MediaDeviceFailure) => void;
}

export function useLiveKitRoom({
    onMediaDeviceFailure,
    onError,
}: LiveKitRoomOptions = {}) {
    const roomStore = useRoomStore();
    const roomNotificationsStore = useRoomNotificationsStore();
    const layoutStore = useLayoutStore();
    const userStore = useUserStore();
    const { userChoices } = usePersistentUserChoices();

    const url = import.meta.env.VUE_APP_LIVEKIT_HOST;
    const isLoggerEnabled = import.meta.env.VUE_APP_LOGGER_ENABLED;

    const room = shallowReactive(
        new Room({
            adaptiveStream: { pixelDensity: 'screen' },
            dynacast: true,
            videoCaptureDefaults: {
                deviceId: userChoices.videoDeviceId,
                resolution: VideoPresets.h720,
            },
            audioCaptureDefaults: {
                deviceId: userChoices.audioDeviceId,
            },
            audioOutput: {
                deviceId: userChoices.audioOutputDeviceId,
            },
            publishDefaults: {
                red: false,
                dtx: false,
                videoSimulcastLayers: [VideoPresets.h540, VideoPresets.h216],
            },
        }),
    );

    const onSignalConnected = () => {
        const localP = room.localParticipant;

        Promise.all([
            localP.setMicrophoneEnabled(userChoices.audioEnabled),
            localP.setCameraEnabled(userChoices.videoEnabled),
        ]).catch((e) => {
            onError?.(e as Error);
        });
    };

    const handleMediaDeviceError = (e: Error) => {
        const mediaDeviceFailure = MediaDeviceFailure.getFailure(e);
        onMediaDeviceFailure?.(mediaDeviceFailure);
    };

    const onConnected = async () => {
        const { t } = i18n.global;
        const audio = new Audio(enterAudio);

        audio.play();

        roomStore.vcsSocket.emit('UpdateParticipantMetadata', {
            canUpdateMetadata: true,
        });

        await Promise.allSettled([
            roomStore.updateMembers(),
            userStore.handlePermissions(roomStore.meetingRoom?.id!),
        ]);

        roomStore.lkDataChannel.emit('Notification', {
            nickname: userStore.profile?.nickname ?? '',
            type: NotificationType.success,
            icon: ['fas', 'user-plus'],
            action: t('vks.notifications.enter'),
        });

        layoutStore.toggleLoadingScreen(false);

        roomStore.vcsSocket.setClientData({
            id: userStore.userId ?? null,
            meetingId: roomStore.meetingRoom?.id ?? null,
            streamId: room.localParticipant.identity,
        });
    };

    const onDisconnected = () => {
        redirect({ name: 'MeetingsPage' });
    };

    const onParticipantConnected = () => {
        const audio = new Audio(enterAudio);
        audio.play();

        roomStore.updateMembers();
    };

    const onParticipantDisconnected = (participant: Participant) => {
        const { t } = i18n.global;

        const audio = new Audio(leaveAudio);
        audio.play();

        const nickname = roomStore.members[participant.identity]?.nickname;

        roomNotificationsStore.addNotification({
            nickname,
            icon: ['fas', 'user-times'],
            type: NotificationType.error,
            action: t('vks.notifications.leave'),
        });

        roomStore.updateMembers();
    };

    const connectToRoom = (token: string) => {
        return room.connect(url, token);
    };

    setLogLevel(isLoggerEnabled ? LogLevel.info : LogLevel.warn);

    room.on(RoomEvent.SignalConnected, onSignalConnected)
        .on(RoomEvent.Connected, onConnected)
        .on(RoomEvent.Disconnected, onDisconnected)
        .on(RoomEvent.ParticipantConnected, onParticipantConnected)
        .on(RoomEvent.ParticipantDisconnected, onParticipantDisconnected)
        .on(RoomEvent.MediaDevicesError, handleMediaDeviceError)
        .prepareConnection(url);

    return { room, connectToRoom };
}
