import { participantInfoObserver } from '@livekit/components-core';
import { Participant } from 'livekit-client';
import { useObservableState } from './useObservableState';
import { computed, MaybeRefOrGetter, toValue } from 'vue';
import { Reaction } from '@/interfaces/webinar';
import { safeJSONParse } from '../utils';
import { useRoomStore } from '../pinia/stores/room';
import { storeToRefs } from 'pinia';

export interface ParticipantMetadata {
    isCameraMirroring: boolean;
    isHandRaised: boolean;
    reaction: Reaction | null;
}

export const defaultMetadata: ParticipantMetadata = {
    isCameraMirroring: false,
    isHandRaised: false,
    reaction: null,
} as const;

export function useParticipantMetadata(
    participant: MaybeRefOrGetter<Participant>,
) {
    const observable = computed(() =>
        participantInfoObserver(toValue(participant)),
    );

    const info = useObservableState(observable, {
        name: toValue(participant).name,
        identity: toValue(participant).identity,
        metadata: toValue(participant).metadata,
    });

    const metadata = computed<ParticipantMetadata>(() => ({
        ...defaultMetadata,
        ...safeJSONParse(info.value.metadata || '{}'),
    }));

    return { metadata };
}

export function useLocalParticipantMetadata() {
    const { lkRoom } = storeToRefs(useRoomStore());
    const localP = computed(() => lkRoom.value.localParticipant);
    const { metadata } = useParticipantMetadata(localP);

    const updateParticipantMetadata = (data: Partial<ParticipantMetadata>) => {
        return localP.value.setMetadata(
            JSON.stringify({ ...metadata.value, ...data }),
        );
    };

    return {
        metadata,
        updateParticipantMetadata,
    };
}
