import { ref, watch, onWatcherCleanup, toRefs, toValue } from 'vue';
import { toReactive } from '@vueuse/core';

//@ts-ignore
import type { Observable } from 'rxjs';
import type { MaybeRefOrGetter, Ref } from 'vue';

export type UseObservableStateReturn<T, H> = H extends true
    ? { [K in keyof T]: Ref<T[K]> }
    : Ref<T>;

export function useObservableState<T, H extends boolean = false>(
    observable: MaybeRefOrGetter<Observable<T> | undefined>,
    startWith: T,
    transformToRefs?: H,
): UseObservableStateReturn<T, H> {
    const state = ref<T>(startWith) as Ref<T>;
    const setState = (value: T) => {
        state.value = value;
    };

    watch(
        () => toValue(observable),
        (observable) => {
            if (!observable) return;

            const subscription = observable.subscribe(setState);
            onWatcherCleanup(() => subscription.unsubscribe());
        },
        { immediate: true },
    );

    if (transformToRefs) {
        return toRefs(toReactive(state)) as UseObservableStateReturn<T, H>;
    }

    return state as UseObservableStateReturn<T, H>;
}
