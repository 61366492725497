import api, { handleApiError } from '@/api';
import type { ApiResponse } from '@/api/types';
import { Member as SdkMember } from '@index5/face-detector';
import { mapMembers } from '@/utils/mappers/members';
import { Member } from './types';

export const getMeetingMembers = async (
    meetingId: string,
): ApiResponse<Record<string, Member>> => {
    try {
        const { data } = await api.get<SdkMember[]>(
            `members?meeting_id=${meetingId}`,
        );

        return { data: mapMembers(data), success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const addUserToMeeting = async (
    meetingId: string,
): ApiResponse<null> => {
    try {
        await api.post('members', { meetingId });

        return { data: null, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};
