import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { BaseNotification, RequestNotification } from '@/interfaces/webinar';
import { useRoomStore } from './room';
import { tryOnMounted } from '@vueuse/core';

export const useRoomNotificationsStore = defineStore(
    'roomNotifications',
    () => {
        const roomStore = useRoomStore();

        const notifications = ref<BaseNotification[]>([]);
        const requests = ref<RequestNotification[]>([]);

        const addNotification = (payload: Omit<BaseNotification, 'id'>) => {
            const notification: BaseNotification = {
                ...payload,
                id: crypto.randomUUID(),
            };

            notifications.value.push(notification);

            setTimeout(() => {
                notifications.value = notifications.value.filter(
                    ({ id }) => id !== notification.id,
                );
            }, 5000);
        };

        const addRequestNotification = (request: RequestNotification) => {
            const isAlreadyRequested = requests.value.some(
                ({ type, memberId }) =>
                    type === request.type && memberId === request.memberId,
            );

            if (isAlreadyRequested) return;

            requests.value.push(request);
        };

        const removeRequestNotification = (
            request: Pick<RequestNotification, 'type' | 'memberId'>,
        ) => {
            requests.value = requests.value.filter(
                ({ type, memberId }) =>
                    !(type === request.type && memberId === request.memberId),
            );
        };

        tryOnMounted(() => {
            roomStore.lkDataChannel
                .on('RequestNotification', addRequestNotification)
                .on('Notification', addNotification);
        }, false);

        return {
            notifications,
            requests,
            addNotification,
            addRequestNotification,
            removeRequestNotification,
        };
    },
);

if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(useRoomNotificationsStore, import.meta.hot),
    );
}
