import {
    ConnectionState,
    DataPacket_Kind,
    DataPublishOptions,
    Participant,
    Room,
    RoomEvent,
} from 'livekit-client';
import { createLogger } from '@/utils';
import {
    EventHandler,
    LiveKitJson,
    MessageManagerCallbacks,
    MessageManagerEvent,
} from './types';

export class LivekitMessageManager {
    private readonly encoder: TextEncoder = new TextEncoder();
    private readonly decoder: TextDecoder = new TextDecoder();

    private readonly logger = createLogger('Livekit Message Manager');

    private events: Map<MessageManagerEvent, EventHandler> = new Map();

    constructor(private readonly room: Room) {
        this.room.on(RoomEvent.DataReceived, this.handleMessage);
    }

    public emit<T extends MessageManagerEvent>(
        event: T,
        payload?: Parameters<MessageManagerCallbacks[T]>[0],
        publishOptions?: DataPublishOptions,
    ) {
        const jsonRpc: LiveKitJson = {
            event,
            data: payload,
        };

        const data = this.encoder.encode(JSON.stringify(jsonRpc));

        try {
            if (this.room.state !== ConnectionState.Connected) {
                throw Error(`room sate is ${this.room.state}`);
            }

            this.room.localParticipant.publishData(data, {
                ...publishOptions,
                reliable: publishOptions?.reliable ?? true,
            });

            this.logger.log('DEBUG | Sent', jsonRpc);
        } catch (error) {
            this.logger.error('emit | Publish data error |', error);
        }
    }

    public terminate() {
        this.room.off(RoomEvent.DataReceived, this.handleMessage);
        this.events.forEach((_, event) => this.off(event));
    }

    public on<T extends MessageManagerEvent>(
        event: T,
        handler: MessageManagerCallbacks[T],
    ) {
        this.events.set(event, handler);
        return this;
    }

    public off(event: MessageManagerEvent) {
        this.events.delete(event);
    }

    public removeAllListeners() {
        this.events.clear();
    }

    public hasListeners(key: MessageManagerEvent) {
        return this.events.has(key);
    }

    private handleMessage = (
        data: Uint8Array,
        participant?: Participant,
        kind?: DataPacket_Kind,
    ) => {
        try {
            const jsonRpc: LiveKitJson = JSON.parse(this.decoder.decode(data));
            this.logger.log('DEBUG | Received', jsonRpc);
            const subscribedHandler = this.events.get(jsonRpc.event);

            if (!subscribedHandler) {
                return;
            }

            subscribedHandler(jsonRpc.data, participant, kind);
        } catch (error) {
            this.logger.error('handleMessage | Publish data error |', error);
        }
    };
}
