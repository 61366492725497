import { reactive, watch } from 'vue';
import { useUserStore } from '@/pinia/stores/user';
import { useRoomStore } from '@/pinia/stores/room';
import { MeetingActions } from '@/constants/roles';
import {
    getRecordingsState,
    startRecording,
    stopRecording,
} from '@/api/recording';
import { createLogger } from '@/utils';
import { RecordingState } from '@/api/recording/types';
import { useRoomNotificationsStore } from '../pinia/stores/roomNotifications';
import i18n from '../i18n';

export function useRecordingState() {
    const logger = createLogger('Recording');

    const userStore = useUserStore();
    const roomStore = useRoomStore();
    const roomNotificationsStore = useRoomNotificationsStore();

    const recordingState = reactive<RecordingState>({
        isStopped: true,
        startDateTime: undefined,
    });

    async function toggleRecordingState() {
        const meetingId = roomStore.meetingRoom?.id;
        const canToggleRecording = recordingState.isStopped
            ? userStore.can(MeetingActions.RecordingStart)
            : userStore.can(MeetingActions.RecordingStop);

        if (!canToggleRecording || !meetingId) {
            return false;
        }

        await loadRecordingState();

        const response = await (recordingState.isStopped
            ? startRecording({ roomName: roomStore.lkRoom.name, meetingId })
            : stopRecording({ meetingId }));

        if (!response.success) {
            logger.error(response.message);
            return false;
        }

        await loadRecordingState();

        roomStore.lkDataChannel.emit('ToggleRecordingState', {
            isStopped: recordingState.isStopped,
        });

        handleNotification(userStore.profile?.nickname ?? '');

        return true;
    }

    async function loadRecordingState() {
        const meetingId = roomStore.meetingRoom?.id;

        if (!meetingId) {
            logger.error('Meeting Room is undefined');
            return;
        }

        const response = await getRecordingsState({ meetingId });

        if (!response.success) {
            logger.error(response.message);
            return;
        }

        const { isStopped, startDateTime } = response.data;

        recordingState.isStopped = isStopped;
        recordingState.startDateTime = startDateTime;
    }

    function handleNotification(nickname: string) {
        const { t } = i18n.global;
        const { isStopped } = recordingState;

        roomNotificationsStore.addNotification({
            nickname,
            icon: ['fas', isStopped ? 'video-slash' : 'video'],
            action: isStopped
                ? t('vks.notifications.reactions.action.record.stop')
                : t('vks.notifications.reactions.action.record.start'),
        });
    }

    // TODO remove watch
    watch(
        () => roomStore.lkDataChannel,
        () => {
            roomStore.lkDataChannel.on(
                'ToggleRecordingState',
                async (_, participant) => {
                    await loadRecordingState();

                    if (!participant) return;

                    handleNotification(
                        roomStore.members[participant.identity]?.nickname,
                    );
                },
            );
        },
    );

    return {
        recordingState,
        toggleRecordingState,
        loadRecordingState,
    };
}
