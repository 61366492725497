import { RouteLocationNamedRaw } from 'vue-router';
import dayjs from 'dayjs';
import i18n from '@/i18n';
import router from '@/router';
import { useUserStore } from '@/pinia/stores/user';
import { ReactionUnicode } from '@/constants/webinar';
import {
    USER_AVATAR_PLACEHOLDER,
    REQUEST_FULLSCREEN_METHODS,
    EXIT_FULLSCREEN_METHODS,
} from '@/constants';
import {
    CreateWebinarLinkPayload,
    ThrottledFunction,
} from '@/interfaces/utils';
import { Meeting } from '@/api/meetings/types';

export function formatTwoDigits(n: number) {
    return n > 9 ? '' + n : '0' + n;
}

export function convertToRGBA(rgbColor: string, aplha: number) {
    const rgbArr = rgbColor
        .slice(4, -1)
        .split(',')
        .map((num) => Number(num.trim()));
    const [r, g, b] = rgbArr;

    const rgbaColor = `rgba(${r}, ${g}, ${b}, 0.${aplha})`;

    return rgbaColor;
}

export type SvgSize = number | 'auto-fit';

export function getFormattedSize(size: SvgSize) {
    const isSizeAutoFit = size === 'auto-fit';
    return isSizeAutoFit ? '100%' : `${size}px`;
}

export function getFullPathImage(subpath: string) {
    return window.location.origin + subpath;
}

export function humanReadableFileSize(bytes: File['size']) {
    if (bytes < 1000) {
        return `${bytes} B`;
    }
    const prefix = ['k', 'M', 'G'];
    let unit = -1;
    while (Math.abs(bytes) >= 1000 && unit < prefix.length - 1) {
        bytes /= 1000;
        ++unit;
    }
    return `${bytes.toFixed(1)} ${prefix[unit]}B`;
}

export function getReactionImg(unicode?: ReactionUnicode): string {
    return requireAsset(`reactions/${unicode}.png`);
}

export function onAvatarLoadError(e: Event) {
    (e.target as HTMLImageElement).src = USER_AVATAR_PLACEHOLDER;
}

export function replaceS3Domain(url: string): string {
    return url.replace(
        import.meta.env.VUE_APP_S3_DOMAIN,
        import.meta.env.VUE_APP_SELECTEL_DOMAIN,
    );
}

export function debounce<T extends Function>(fn: T, ms = 300) {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
}

export function throttle<T extends (...args: any) => any>(
    func: T,
    limit: number,
): ThrottledFunction<T> {
    let inThrottle: boolean;
    let lastResult: ReturnType<T>;

    return function (this: any, ...args): ReturnType<T> {
        const context = this;

        if (!inThrottle) {
            inThrottle = true;

            setTimeout(() => (inThrottle = false), limit);

            lastResult = func.apply(context, args);
        }

        return lastResult;
    };
}

export function capitalize(string: string) {
    return string[0].toUpperCase() + string.slice(1);
}

export function createWebinarLink(payload: CreateWebinarLinkPayload) {
    const { name, organizer, id, date } = payload;
    const { t, locale } = i18n.global;

    const isPlanned = date.start.toTimeString() !== date.created.toTimeString();
    const format = isPlanned ? 'D MMMM, HH:mm' : 'D MMMM';
    const formattedDate = dayjs(date.start).format(format);
    const weekday = capitalize(dayjs(date.start).format('dddd'));

    return t('webinar-link', {
        name,
        organizer,
        date: `${weekday}, ${formattedDate}`,
        link: `${window.location.origin}/#/${locale.value}/webinar/${id}`,
    });
}

export function redirect(to: RouteLocationNamedRaw) {
    const locale = i18n.global.locale.value;

    const params = 'params' in to ? to.params ?? {} : {};
    return router.push({ name: to.name, params: { ...params, locale } });
}

export function bufferToText(data: number[]) {
    const uint8Array = new Uint8Array(data);
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(uint8Array);
}

function getFileUrl(blob: Blob | string) {
    return blob instanceof Blob
        ? URL.createObjectURL(blob)
        : fetch(blob)
              .then((response) => response.blob())
              .then((blob) => URL.createObjectURL(blob));
}

export async function downloadFile(
    blob: Blob | string,
    name: string,
    format?: string,
): Promise<void> {
    const url = await getFileUrl(blob);
    const link = document.createElement('a');
    const fileName = format ? `${name}.${format}` : name;

    link.setAttribute('download', fileName);
    link.href = url;

    document.body.appendChild(link);

    link.click();
    link.remove();

    URL.revokeObjectURL(url);
}

export function getStatusColorByAttention(attention: number) {
    if (attention <= 20) return '#FA5555';
    if (attention <= 60) return '#FF9D0A';

    return '#67C23A';
}

export function generateRandomColor(): string {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

export function requireAsset(path: string): string {
    return new URL(`../assets/${path}`, import.meta.url).href;
}

export const checkIsFullScreenAvailable = (
    element: HTMLElement | null,
): boolean => {
    return (
        !!element &&
        REQUEST_FULLSCREEN_METHODS.some((method) => method in element)
    );
};

export async function requestFullscreen(
    element: HTMLElement | null,
    options?: FullscreenOptions,
): Promise<void> {
    if (!element) return;

    for (const method of REQUEST_FULLSCREEN_METHODS) {
        if (method in element) {
            return element[method](options);
        }
    }
}

export async function exitFullscreen(): Promise<void> {
    for (const method of EXIT_FULLSCREEN_METHODS) {
        if (method in document) {
            return document[method]();
        }
    }
}

export function safeJSONParse<T>(text: string) {
    try {
        const value: T = JSON.parse(text);
        return value;
    } catch {
        return null;
    }
}

export function copyMeetingLink(meeting: Meeting) {
    const userStore = useUserStore();

    return navigator.clipboard.writeText(
        createWebinarLink({
            id: meeting.id,
            name: meeting.name,
            organizer: userStore.profile?.nickname ?? '',
            date: {
                start: new Date(meeting.start_date_time),
                created: new Date(meeting.createdAt),
            },
        }),
    );
}

export function isObject(val: unknown): val is object {
    return (
        val != null && typeof val === 'object' && Array.isArray(val) === false
    );
}
