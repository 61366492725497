import {
    createSharedComposable,
    useLocalStorage,
    toReactive,
} from '@vueuse/core';

export interface UserChoices {
    videoMirrored: boolean;
    videoEnabled: boolean;
    audioEnabled: boolean;
    videoDeviceId: string;
    audioDeviceId: string;
    audioOutputDeviceId: string;
    username: string;
}

export const defaultUserChoices: UserChoices = {
    videoMirrored: false,
    videoEnabled: true,
    audioEnabled: true,
    videoDeviceId: '',
    audioDeviceId: '',
    audioOutputDeviceId: '',
    username: '',
} as const;

export const usePersistentUserChoices = createSharedComposable(() => {
    const refUserChoices = useLocalStorage('userChoices', defaultUserChoices);
    const userChoices = toReactive(refUserChoices);

    const saveVideoMirrored = (isMirrored: boolean) => {
        userChoices.videoMirrored = isMirrored;
    };

    const saveAudioInputEnabled = (isEnabled: boolean) => {
        userChoices.audioEnabled = isEnabled;
    };

    const saveVideoInputEnabled = (isEnabled: boolean) => {
        userChoices.videoEnabled = isEnabled;
    };

    const saveVideoInputDeviceId = (deviceId: string) => {
        userChoices.videoDeviceId = deviceId;
    };

    const saveAudioInputDeviceId = (deviceId: string) => {
        userChoices.audioDeviceId = deviceId;
    };

    const saveAudioOutputDeviceId = (deviceId: string) => {
        userChoices.audioOutputDeviceId = deviceId;
    };

    const saveUsername = (username: string) => {
        userChoices.username = username;
    };

    return {
        userChoices,
        saveVideoMirrored,
        saveAudioInputEnabled,
        saveVideoInputEnabled,
        saveVideoInputDeviceId,
        saveAudioInputDeviceId,
        saveAudioOutputDeviceId,
        saveUsername,
    };
});
