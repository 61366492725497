import api, { handleApiError } from '@/api';
import { ApiResponse } from '../types';
import {
    StartRecording,
    GetRecordingFiles,
    DeleteRecording,
    StopRecording,
    Record,
    GetState,
    RecordingState,
} from './types';

export const startRecording = async (
    payload: StartRecording,
): ApiResponse<string> => {
    try {
        const { data } = await api.post<string>(
            `recordings/${payload.meetingId}`,
            payload,
            { headers: { 'X-Meeting-Id': payload.meetingId } },
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const stopRecording = async (
    payload: StopRecording,
): ApiResponse<string> => {
    try {
        const { data } = await api.patch<string>(
            `recordings/${payload.meetingId}`,
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const getRecordingsState = async (
    payload: GetState,
): ApiResponse<RecordingState> => {
    try {
        const { data } = await api.get<RecordingState>(
            `recordings/${payload.meetingId}/state`,
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const getRecordings = async (): ApiResponse<Record[]> => {
    try {
        const { data } = await api.get<Record[]>('recordings');

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const deleteRecording = async (
    payload: DeleteRecording,
): ApiResponse<null> => {
    try {
        await api.delete(`recordings/${payload.meetingId}/${payload.recordId}`);

        return { data: null, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};

export const getRecordingFiles = async (
    payload: GetRecordingFiles,
): ApiResponse<Blob> => {
    try {
        const { data } = await api.get<Blob>(
            `recordings/files/${payload.path}`,
            { responseType: 'blob' },
        );

        return { data, success: true };
    } catch (error) {
        return handleApiError(error);
    }
};
